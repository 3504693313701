import * as React from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../api/baseApi';
import { getUserPermissions } from '../api/userApi';
import { AuthContext } from '../authentication/authContext';
import { UserPermission } from '../types/enums';
import { UserPermissions, UserPreferredView } from '../types/user';

interface Props {
    children?: React.ReactNode;
}

interface UserPermissionsContext {
    userHasPermission: (permission: UserPermission) => boolean;
}

const defaultLimitedUserPermissions: UserPermissions = {
    mfaEnabled: false,
    viewAffiliates: false,
    lookupChecks: false,
    editSettlements: false,
    editConsumers: false,
    viewLedger: false,

    affiliateAccess: [],
    reportTypeAccess: []
};

const defaultUnlimitedPermissions: UserPermissions = {
    mfaEnabled: false,
    viewAffiliates: true,
    lookupChecks: true,
    editSettlements: true,
    editConsumers: true,
    viewLedger: true,

    affiliateAccess: [],
    reportTypeAccess: []
};

export const UserAuthorizationContext = createContext<UserPermissionsContext>({
    ...defaultLimitedUserPermissions,
    userHasPermission: () => false
});

export const UserAuthorizationService = (props: Props) => {
    const { userId, isUnlimitedUser, savePermissions } = useContext(AuthContext);

    const [userPermissions, setUserPermissions] = useState<UserPermissions>(
        isUnlimitedUser() ? defaultUnlimitedPermissions : defaultLimitedUserPermissions
    );

    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) =>
            error || error == '' &&
            addToast(`Failed to load user permissions (${error})`, {
                appearance: 'error',
                autoDismiss: true
            }),
        [addToast]
    );

    useEffect(() => {
        if (userId) {
            loadFromApi(setApiError)(() => getUserPermissions(userId),
            (data:UserPreferredView) => {
                setUserPermissions(data);
                savePermissions(data);
            })
        }
    }, [setApiError, userId]);

    const userHasPermission = (permission: UserPermission) => {
        if (isUnlimitedUser()) {
            return true;
        }

        switch (permission) {
            case UserPermission.ViewAffiliates:
                return userPermissions.viewAffiliates;
            case UserPermission.ViewChecks:
                return userPermissions.lookupChecks;
            case UserPermission.ViewLedger:
                return userPermissions.viewLedger;
            case UserPermission.EditSettlements:
                return userPermissions.editSettlements;
            case UserPermission.EditConsumers:
                return userPermissions.editConsumers;
            default:
                return false;
        }
    };

    const userPermissionsContext: UserPermissionsContext = {
        userHasPermission
    };

    return (
        <UserAuthorizationContext.Provider value={userPermissionsContext}>
            {props.children}
        </UserAuthorizationContext.Provider>
    );
};
