import * as React from 'react';
import '../../../styles/views/UserSettings.scss';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { AuthContext } from '../../../authentication/authContext';
import {GridViewContext} from '../../GridViewService';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { loadFromApi } from '../../../api/baseApi';
import { getCreditorName, updatePreferredView } from '../../../api/userApi';
import { useCallback, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { getMfaAuthSetupToken } from '../../../api/mfaApi';

function UserSettingsPage() {    
    const [qrCode, showQrCode] = useState(false);
    const [mfaSetupToken, setMfaSetupToken] = useState<any>();
    const [otpUri, setOtpUri] = useState('');
    const { changeView, currentView } = React.useContext(GridViewContext);
    const { changePassword, loggedInUser, changeUser, id } = React.useContext(AuthContext);
    const [view, setView] = React.useState(1);
    const [name, setName] = React.useState('');
    const [creditorData, setCreditorData] = React.useState<any>([]);
    const [roleid, setId] = React.useState(localStorage.getItem('id'));
    const userViewPref:  { [key: string]: Object }[] = [
        { viewId: 1, viewType: 'Default View' },
        { viewId: 2, viewType: 'Comfort View' },
        { viewId: 3, viewType: 'Compact View' }
    ];
    const fields: object = { text: 'viewType', value: 'viewId' };
    const userfields: object = { text: 'creditorName', value: 'creditorID' };
    const onChange = (args: any) =>{
        changeView && changeView(args.itemData.viewId);
        setView(args.itemData.viewId);
        if(loggedInUser){
            loadFromApi(()=> {})(() => 
            updatePreferredView(loggedInUser.userId,args.itemData.viewId ))
        }
    }

    const handleChange = (args: any) =>{
        localStorage.setItem('id', args.value)
        changeUser && changeUser(args.value);
    }

    React.useEffect(() => {
        let data = creditorData.filter((data: any) =>  data.creditorID == id );
        data[0] && setName(data[0].creditorName)
    }, [creditorData])

    React.useEffect(() => {
        
        if(id !== null){
            loadFromApi(()=> {})(() =>  
            getCreditorName(id), setCreditorData)
        }   
        
    }, [])
    const setApiError = useCallback(
        (error?: string) => error  ,
        []
    );
    React.useEffect(() => {
        if (mfaSetupToken?.mfaSetupToken) {
            showQrCode(true);
            const otpUri = `otpauth://totp/Reliant:${loggedInUser.username}?secret=${mfaSetupToken.mfaSetupToken}&issuer=Reliant`;
            setOtpUri(otpUri);
        } else {
            showQrCode(false);
        }
    }, [mfaSetupToken]);

    
    const loadMfaProperties = () => {
        const apiWrapper = loadFromApi(setApiError);
        const reloadPageData = async () => {
            await apiWrapper(() => getMfaAuthSetupToken(loggedInUser.userId), setMfaSetupToken);
        };
        reloadPageData();
    };
    return (
        <div className="page-user-preferences">
            <div className="main-window">
                <div className='row'>
                    <h2>User Settings</h2>
                </div>
                <div className='row'>
                    <div className='first-column'>
                            <div>Creditor Name</div>
                            {!loggedInUser?.roles.includes('Superuser') && <p>{name}</p>}
                    </div>
                    {loggedInUser?.roles.includes('Superuser') && roleid && <div className='second-column'>
                        <DropDownListComponent value={Number(roleid)} onChange={handleChange}
                                 dataSource={creditorData} fields={userfields}></DropDownListComponent>
                    </div>}
                            
                            
                            
                            
                    
                </div>
                <div className='row email-section'>
                
                   <div className='first-column'>
                            <div>Registered Email</div>
                            <p>{loggedInUser && loggedInUser.email}</p>
                    </div>
                    {/* <div className='second-column'>
                           <p> <ButtonComponent> Change Email</ButtonComponent></p>
                    </div> */}
                </div>
                <div className='row password-section'>
                    <div className='first-column'>
                        <div>Change Password</div>
                        <p>
                            On Clicking change password your registered email will receive an email with instructions to change your password
                        </p>
                    </div>
                    <div className='second-column'>
                            <ButtonComponent onClick={changePassword}>Change Password</ButtonComponent>
                    </div>
                </div>
                <div className='row view-section'>
                   <div className='first-column'>
                        <div>Set Preferred View</div>
                    </div>
                    <div className='second-column'>
                        <DropDownListComponent change={onChange}
                         dataSource={userViewPref} fields={fields} value={currentView !==null && currentView}></DropDownListComponent>
                    </div>
                </div>
                <div className='row mfa-auth-section'>
                    <div className='first-column'>
                        <div>Setup Authenticator App</div>
                        <p>
                            To set up an authenticator app, click the Setup Authenticator button.
                            If you are not currently set up for multi-factor authentication, you will be prompted to do so the next time you log in. 
                            Scan the QR code generated after clicking the button to configure the authenticator app.
                        </p>
                    </div>
                    <div className='second-column'>
                            {qrCode ? <ButtonComponent onClick={() => showQrCode(false)}>Hide QR Code</ButtonComponent> :
                                <ButtonComponent onClick={loadMfaProperties}>Setup Authenticator</ButtonComponent>}
                            {qrCode && (
                                    <div className="card qrcode">
                                        <QRCodeSVG
                                            value={otpUri}
                                        />
                                    </div>
                                )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserSettingsPage;
